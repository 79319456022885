import type {PlatformMediaType, PostState, SocialMediaAccountId, SocialPost} from '@Components/social-media/post.vo';
import type {AccountState} from '@Components/social-media/account.vo';
import type {ContainerQueryObject} from '@Hooks/useContainerQuery';
import type {AccountResizeStatus, ResizeOptionsConfig} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import type {CaptionErrorTypes} from '@Components/social-media-wizard/social-media-wizard.types';
import type {PosterHashedId, RetryStatus} from '@Components/social-media/publish-params.types';

export enum SocialPlatform {
  FACEBOOK = 'facebook',
  FACEBOOK_PAGE = 'fb_page',
  FACEBOOK_PROFILE = 'fb_profile',
  FACEBOOK_GROUP = 'fb_group',
  INSTAGRAM = 'ig-business',
  YOUTUBE = 'youtube-channel',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  LINKEDIN_PROFILE = 'linkedin-profile',
  LINKEDIN_PAGE = 'linkedin-page',
  TIKTOK = 'tiktok-profile',
  PINTEREST = 'pinterest',
  GOOGLE_BUSINESS_PROFILE = 'google-business-profile',
  THREADS = 'threads',
}

export const getReadableAccounts = (platform: SocialPlatform): string => {
  switch (platform) {
    case SocialPlatform.TIKTOK:
      return 'TikTok';
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PROFILE:
    case SocialPlatform.LINKEDIN_PAGE:
      return 'LinkedIn';
    case SocialPlatform.YOUTUBE:
      return 'YouTube';
    case SocialPlatform.FACEBOOK_GROUP:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK:
      return 'Facebook';
    case SocialPlatform.TWITTER:
      return 'X (formerly Twitter)';
    case SocialPlatform.INSTAGRAM:
      return 'Instagram';
    case SocialPlatform.PINTEREST:
      return 'Pinterest';
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return 'Google Business Profile';
    case SocialPlatform.THREADS:
      return 'Threads';
    default:
      return '';
  }
};

export const SocialUniquePlatforms: SocialPlatform[] = [
  SocialPlatform.FACEBOOK,
  SocialPlatform.INSTAGRAM,
  SocialPlatform.TWITTER,
  SocialPlatform.TIKTOK,
  SocialPlatform.LINKEDIN,
  SocialPlatform.YOUTUBE,
  SocialPlatform.PINTEREST,
  SocialPlatform.GOOGLE_BUSINESS_PROFILE,
  SocialPlatform.THREADS,
];

export type PostStateFilter = Record<PostState, boolean>;
export type AccountStateFilter = Record<AccountState, boolean>;
export type CaptionErrorByAccountIds = Record<SocialMediaAccountId, CaptionErrorTypes>;
export type TitleErrorByAccountIds = Record<SocialMediaAccountId, string | null>;
export enum PostSortingMetric {
  LAST_MODIFIED = 'last_modified',
  DATE_PUBLISHED = 'publish_on',
}

export enum AccountSortingMetric {
  DATE_CREATED = 'DATE_CREATED',
  BY_PLATFORM = 'BY_PLATFORM',
}

export enum SocialPostEvent {
  PUBLISHED = 'post:published',
  CREATED = 'post:created',
  DELETED = 'post:delete',
  RESET_GRID = 'post:delete_all',
  SCHEDULED = 'post:scheduled',
  UNSCHEDULED = 'post:unscheduled',
  ERROR = 'post:error',
  UPDATED = 'post:updated',
}

export const SocialPostCaptionOverwriteEvent = 'caption::overwrite';
export const SocialPostPlatformSelectionTransitionEvent = 'socialpost::platform-selection-transition';

export interface SocialPostPlatformSelectionTransitionEventDetail {
  accountId: number;
  platformSelection: PlatformMediaType;
}

export enum SocialAccountEvent {
  CREATED = 'account:created',
}

export interface SocialGridContainerQueryStructure {
  xsmall: boolean;
  small: boolean;
  medium: boolean;
  large: boolean;
  xlarge: boolean;
  xxlarge: boolean;
}

export const socialGridContainerQuery: ContainerQueryObject = {
  xsmall: {
    maxWidth: 460,
  },
  small: {
    maxWidth: 700,
  },
  medium: {
    minWidth: 461,
    maxWidth: 884,
  },
  large: {
    minWidth: 885,
  },
  xlarge: {
    minWidth: 1020,
  },
  xxlarge: {
    minWidth: 1420,
  },
};

export interface PosterNeedsResizeInformation {
  needsResizing: PosterResizeRequiredStatus;
  reelNeedsResizing?: PosterResizeRequiredStatus;
  notSupportedCustomMessage?: string;
  storyNeedsResizing?: PosterResizeRequiredStatus;
}

export interface AccountNeedsResizeMapForDesigns {
  [accountId: number]: PosterResizeInformationHashmap;
}

export type PosterResizeInformationHashmap = Record<PosterHashedId, PosterNeedsResizeInformation>;

export type PublishableUrlForDesign = Record<PosterHashedId, string>;

export interface AccountPublishableUrlsForMediaType {
  [accountId: number]: Record<PlatformMediaType, PublishableUrlForDesign>;
}

export interface PublishableUrlUpdatePayload {
  accountId: number;
  posterId: PosterHashedId;
  platformMediaType: PlatformMediaType;
  graphicUrl: string;
}

export interface RetryStatusUpdatePayload {
  accountId: number;
  platformMediaType: PlatformMediaType;
}

export type PosterResizeInformationHashMap = Record<PosterHashedId, PosterNeedsResizeInformation>;

export interface PublishedUrlUpdatePayload {
  accountId: number;
  mediaType: PlatformMediaType;
  url: string;
}

export type AccountResizeStatusForDesign = Record<PosterHashedId, AccountResizeStatus>;

export interface AccountResizeStatusForMediaType {
  [accountId: number]: Record<PlatformMediaType, AccountResizeStatusForDesign>;
}

export type DesignHasDefaultResizedMap = Record<PosterHashedId, boolean>;

export interface DesignsHaveDefaultResizedForAccountMap {
  [accountId: number]: Record<PlatformMediaType, DesignHasDefaultResizedMap>;
}

export interface PublishedAccountUrlMap {
  [accountId: number]: string;
}

export enum PosterResizeRequiredStatus {
  RESIZE_NEEDED = 'RESIZE_NEEDED',
  BACKEND_OPTIMIZATION_NEEDED = 'BACKEND_OPTIMIZATION_NEEDED',
  RESIZE_NOT_NEEDED = 'RESIZE_NOT_NEEDED',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export type PublishedUrlByMediaType = Record<PlatformMediaType, string | null>;

export interface PublishedUrlMapForAccount {
  [accountId: number]: PublishedUrlByMediaType;
}

export type RetryStatusForMediaType = Record<PlatformMediaType, RetryStatus | null>;

export interface SocialMediaResizeAjaxParams {
  posterId: string;
  resizeType?: ResizeOptionsConfig;
  socialMediaPlatform: SocialPlatform;
  socialAccountId: SocialMediaAccountId;
  idPost: string;
  platformMediaType: PlatformMediaType;
  isVideo: boolean;
  fillColor?: string;
  isPreview?: boolean;
}

export interface SocialMediaResizeResponse {
  s3FilePath?: string;
  videoS3FileName?: string;
}

export type MultiGraphicDesignSelectorModeType = MultiGraphicDesignSelectorModes.Select | MultiGraphicDesignSelectorModes.Resize;

export enum MultiGraphicDesignSelectorModes {
  Select = 'select',
  Resize = 'resize',
}

export interface MultipleDesignMetaForPublishing {
  designsIsVideo: boolean[];
  designsNames: string[];
  post: SocialPost;
  accountNeedsResizeMap?: object;
  accountNeedsResizeMapForDesigns?: object;
}

export interface DesignIsVideoInformation {
  isVideo: boolean;
  designName: string;
}
