import {createAsyncThunk} from '@reduxjs/toolkit';
import type {PostSortingMetric} from '@Components/social-media/social-media.types';
import type {MediaTypePublishingParams, PlatformMediaType, SocialMediaAccountId, SocialMediaAccountIdString} from '@Components/social-media/post.vo';
import type {SocialMediaAccountsResponse, SocialMediaPostsResponse} from './social-media-extra-reducers';

export interface SocialMediaGridRequestThunkParams {
  offset: number;
  limit: number;
  getDeleted: boolean;
  sortPostsBy: PostSortingMetric;
}

export interface AccountSelectionPreferencePayload {
  socialMediaAccountIds: SocialMediaAccountId[];
  idPost: string | undefined;
}

export interface PostForAccountParams {
  idPost: string | null;
  idSocialMediaAccount: SocialMediaAccountId;
  isRetryPostOrReel?: boolean;
  isRetryStory?: boolean;
}

export interface PublishForAccountParams {
  idPost: string | null;
  idSocialMediaAccount: SocialMediaAccountId;
  isRetry: boolean;
  mediaType: PlatformMediaType;
}

export interface SocialMediaPublishingParamsUpdateParams {
  idPost: string | null;
  idDesigns: string[] | null;
  publishOn: number | null;
  timeZoneOffset: number | null;
  publishingParams: Record<SocialMediaAccountIdString, MediaTypePublishingParams>;
}

export const getSocialMediaPostsForUser = createAsyncThunk<SocialMediaPostsResponse, SocialMediaGridRequestThunkParams>(
  'getSocialMediaPostsForUser',
  async ({offset, limit, getDeleted, sortPostsBy}): Promise<SocialMediaPostsResponse> => {
    return window.PMW.readLocal('socialmedia/getSocialMediaPostsForUser', {
      offset,
      limit,
      getDeleted,
      sortPostsBy,
    });
  }
);

export const getPostableSocialMediaAccountsForUser = createAsyncThunk<SocialMediaAccountsResponse>(
  'getPostableSocialMediaAccountsForUser',
  async (): Promise<SocialMediaAccountsResponse> => {
    return window.PMW.readLocal('socialmedia/getPostableSocialMediaAccountsForUser');
  }
);

export const savePreferredProfileSelectionUser = createAsyncThunk('savePreferredProfileSelectionUser', async (data: AccountSelectionPreferencePayload) => {
  return window.PMW.writeLocal('socialmedia/savePreferredProfileSelectionUser', {
    socialMediaAccountIds: data.socialMediaAccountIds,
    idPost: data.idPost,
  });
});

export const doPublishForAccount = createAsyncThunk('doPublishPostOrReelForAccount', async (data: PublishForAccountParams) => {
  return window.PMW.pollingAjaxCallAsync(
    () => {
      return window.PMW.writeLocal(`socialmedia/doPublishForAccount`, {
        idPost: data.idPost,
        idSocialMediaAccount: data.idSocialMediaAccount,
        isRetry: data.isRetry,
        mediaType: data.mediaType,
      });
    },
    () => {
      return window.PMW.writeLocal('socialmedia/isPostPublished', {
        idPost: data.idPost,
        idSocialMediaAccount: data.idSocialMediaAccount,
        mediaType: data.mediaType,
      });
    }
  );
});

export const updateSocialMediaPostPublishingParams = createAsyncThunk('updateSocialMediaPostPublishingParams', async (data: SocialMediaPublishingParamsUpdateParams) => {
  return window.PMW.writeLocal('socialmedia/updateSocialMediaPostPublishingParams', {
    idPost: data.idPost,
    idDesigns: data.idDesigns,
    publishOn: data.publishOn,
    timeZoneOffset: data.timeZoneOffset,
    publishingParams: data.publishingParams,
  });
});

export const getSocialMediaAccountsForUser = createAsyncThunk<SocialMediaAccountsResponse>('getSocialMediaAccountsForUser', async (): Promise<SocialMediaAccountsResponse> => {
  return window.PMW.readLocal('socialmedia/getSocialMediaAccountsForUser');
});
